import { defineMessages } from '@edx/frontend-platform/i18n';

const messages = defineMessages({
  beginCourse: {
    id: 'learner-dash.courseCard.actions.beginCourse',
    description: 'Course card begin-course button text',
    defaultMessage: 'Begin Course',
  },
  resume: {
    id: 'learner-dash.courseCard.actions.resume',
    description: 'Course card resume button text',
    defaultMessage: 'Resume',
  },
  viewCourse: {
    id: 'learner-dash.courseCard.actions.viewCourse',
    description: 'Course card view-course button text',
    defaultMessage: 'View Course',
  },
  selectSession: {
    id: 'learner-dash.courseCard.actions.selectSession',
    description: 'Course card select-session button text',
    defaultMessage: 'Select Session',
  },
});

export default messages;
